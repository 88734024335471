exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-about-us-about-us-tsx": () => import("./../../../src/templates/AboutUs/AboutUs.tsx" /* webpackChunkName: "component---src-templates-about-us-about-us-tsx" */),
  "component---src-templates-ces-ces-tsx": () => import("./../../../src/templates/CES/CES.tsx" /* webpackChunkName: "component---src-templates-ces-ces-tsx" */),
  "component---src-templates-connect-with-us-connect-with-us-tsx": () => import("./../../../src/templates/ConnectWithUs/ConnectWithUs.tsx" /* webpackChunkName: "component---src-templates-connect-with-us-connect-with-us-tsx" */),
  "component---src-templates-course-page-course-page-tsx": () => import("./../../../src/templates/CoursePage/CoursePage.tsx" /* webpackChunkName: "component---src-templates-course-page-course-page-tsx" */),
  "component---src-templates-course-simple-course-simple-tsx": () => import("./../../../src/templates/CourseSimple/CourseSimple.tsx" /* webpackChunkName: "component---src-templates-course-simple-course-simple-tsx" */),
  "component---src-templates-ibc-ibc-tsx": () => import("./../../../src/templates/IBC/IBC.tsx" /* webpackChunkName: "component---src-templates-ibc-ibc-tsx" */),
  "component---src-templates-main-main-tsx": () => import("./../../../src/templates/Main/Main.tsx" /* webpackChunkName: "component---src-templates-main-main-tsx" */),
  "component---src-templates-press-details-press-details-tsx": () => import("./../../../src/templates/PressDetails/PressDetails.tsx" /* webpackChunkName: "component---src-templates-press-details-press-details-tsx" */),
  "component---src-templates-press-press-tsx": () => import("./../../../src/templates/Press/Press.tsx" /* webpackChunkName: "component---src-templates-press-press-tsx" */),
  "component---src-templates-privacy-policy-privacy-policy-tsx": () => import("./../../../src/templates/PrivacyPolicy/PrivacyPolicy.tsx" /* webpackChunkName: "component---src-templates-privacy-policy-privacy-policy-tsx" */),
  "component---src-templates-project-example-project-example-tsx": () => import("./../../../src/templates/ProjectExample/ProjectExample.tsx" /* webpackChunkName: "component---src-templates-project-example-project-example-tsx" */),
  "component---src-templates-project-examples-page-project-examples-page-tsx": () => import("./../../../src/templates/ProjectExamplesPage/ProjectExamplesPage.tsx" /* webpackChunkName: "component---src-templates-project-examples-page-project-examples-page-tsx" */),
  "component---src-templates-services-services-tsx": () => import("./../../../src/templates/Services/Services.tsx" /* webpackChunkName: "component---src-templates-services-services-tsx" */),
  "component---src-templates-technology-child-page-technology-child-page-tsx": () => import("./../../../src/templates/TechnologyChildPage/TechnologyChildPage.tsx" /* webpackChunkName: "component---src-templates-technology-child-page-technology-child-page-tsx" */),
  "component---src-templates-technology-technology-tsx": () => import("./../../../src/templates/Technology/Technology.tsx" /* webpackChunkName: "component---src-templates-technology-technology-tsx" */)
}

