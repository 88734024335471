/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import 'normalize.css'
import './src/styles/constants.scss'
import './src/styles/global.scss'

console.log('version: ', process.env.APP_VERSION)

export const onRouteUpdate = ({ prevLocation }) => {
  sessionStorage.setItem('prevPath', prevLocation?.pathname)
}

// You can delete this file if you're not using it
